import { Link, useNavigate } from 'react-router-dom';
import WorkInfo from '../../../components/WorkInfo/WorkInfo';
import "./OrderList.css";
import { useOrderApi } from '../../../services/api/order.api';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { convertDateToYYYYMMMDD, getFullName } from '../../../helpers/utility';
import Container from '../../../components/Container';
import PlusIconButton from '../../../components/Button/PlusIconButton';
import TableActionButton from '../../../components/Button/TableActionButton';
import { useState } from 'react';
import { Margin, usePDF } from 'react-to-pdf';
import Modal from '../../../components/Modal/Modal';
import ConfirmModal from '../../../components/Modal/ConfirmModal';
import { toast } from 'react-toastify';

export default function OrderList() {
  const { toPDF, targetRef } = usePDF({
    method: 'open',
    filename: 'order-report.pdf',
    page: {
      margin: Margin.SMALL,
    },
  });
  const todaysDate = convertDateToYYYYMMMDD(new Date());
  const navigate = useNavigate();
  const OrderApi = useOrderApi();
  const [dataParams, setDataParams] = useState({
    fromDate: todaysDate,
    toDate: todaysDate,
    searchBy: 'dentist',
    searchTerm: '',
  });
  const [filterApplied, setFilterApplied] = useState(true);
  const [filters, setFilters] = useState({
    fromDate: todaysDate,
    toDate: todaysDate,
  });
  const [searchParams, setSearchParams] = useState({
    searchBy: 'dentist',
    searchTerm: '',
  });
  const [showModal, setShowModal] = useState(false);
  const [orderIdToDelete, setOrderIdToDelete] = useState(null);

  const closeModal = () => {
    setOrderIdToDelete(null);
    setShowModal(false);
  }

  const showConfirmModal = (orderId) => {
    setOrderIdToDelete(orderId);
    setShowModal(true);
  }

  const queryClient = useQueryClient();

  const { data: orderData, isLoading, fetchNextPage, isFetching, isFetchingNextPage, hasNextPage } = useInfiniteQuery(['order-list', {
    dataParams,
  }], async ({pageParam}) => {
    return OrderApi.search(dataParams, pageParam).then(res => {
      if (res.status === 'success') {
        return res.result;
      }
    })
  }, {
    getNextPageParam: (lastPage, pages) => {
      return lastPage.nextPageToken;
    },
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });

  const handleSearchInputChange = (event) => {
    setSearchParams(prev => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
  }

  const handleFilterInput = (event) => {
    setFilters(prev => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
  }
  
  const handleSearch = (event) => {
    event.preventDefault();
    setDataParams({
      ...searchParams,
      ...filters
    });
  }
  
  const applyFilter = () => {
    let isFilterApplied = false;
    Object.values(filters).forEach((val) => {
      if (val) {
        isFilterApplied = true;
      }
    })
    setFilterApplied(isFilterApplied);
    setDataParams({
      ...filters,
      ...searchParams,
    });
  }
  const clearFilter = () => {
    setFilterApplied(false);
    setFilters({
      fromDate: '',
      toDate: '',
    });
    setDataParams({
      ...{
        fromDate: '',
        toDate: '',
      },
      ...searchParams,
    });
  }

  const dentistDetail = (dentist) => {
    const address = dentist.address?.length > 30 ? `${dentist.address.substring(0, 27)}...` : dentist.address;
    return <>
      {getFullName(dentist.firstName, dentist.lastName)}
      <br />
      {address ?? ''}
    </>
  }

  const deleteOrder = (recId) => {
    OrderApi.delete(recId).then((res) => {
      if (res.status === 'success') {
        toast.success('Deleted successfully');
        queryClient.invalidateQueries('order-list');
      } else {
        toast.error(res.message);
      }
    }).catch((err) => {
      toast.error(err.message);
    }).finally(() => {
      setOrderIdToDelete(null);
      setShowModal(false);
    });
  }

  let tableData = (<></>);
  if (!isLoading) {
    tableData = (
      <table className="table collapsed-table order-list mt-3">
        <thead className='sticky-top'>
          <tr className="">
            <th scope="col" style={{width: '75px'}}>#</th>
            <th scope="col" style={{width: '130px'}}>Date</th>
            <th scope="col">Dentist</th>
            <th scope="col" style={{width: '280px'}}>Work info</th>
            <th scope="col">Work type</th>
            <th scope="col">Shade</th>
            <th scope="col">Patient Name</th>
            <th scope="col" style={{width: '130px'}}>Dispatch Date</th>
            <th scope="col" style={{width: '80px'}}>Options</th>
          </tr>
        </thead>
        <tbody>
          {
            orderData.pages?.map((page) =>
              page?.listOfItems?.map((rec) => (
                <tr key={rec.id} className="">
                  <th scope="row">
                    <Link className='text-decoration-none text-primary' to={`/orders/${rec.id}/view`}>{rec.id}</Link></th>
                  <td>{rec.orderDate}</td>
                  <td>{dentistDetail(rec.dentist)}</td>
                  <td>
                    <WorkInfo size='15' selectedTeeth={rec.workInfo} />
                  </td>
                  <td>
                      {rec.workType}
                  </td>
                  <td>
                    {rec.shade}
                  </td>
                  <td>{rec.patientName}</td>
                  <td>{rec.dispatchDate}</td>
                  <td style={{textAlign: 'right'}}>
                    <div className="dropdown">
                      <TableActionButton />
                      <ul className="dropdown-menu">
                        <li><Link className='dropdown-item' to={`/orders/${rec.id}/edit`}>Edit</Link></li>
                        <li>
                          <button className='dropdown-item' onClick={() => {showConfirmModal(rec.id)}} aria-hidden="false">
                            Delete
                          </button>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              ))
            )
          }
        </tbody>
      </table>
    )
  }

  return (
    <>
    <Container>
      <div className='container-fluid pt-1'>
        <div className="row">
          <div className="col-12">
            <h4>Manage Orders</h4>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12">
            <div className='row'>
              <div className='col-6'>
                <form onSubmit={handleSearch} className="row row-cols-lg-auto g-3 align-items-center">
                  <div className="col-12">
                    <div className="form-check">
                      <input className="form-check-input" value="dentist" onChange={handleSearchInputChange} checked={searchParams.searchBy === 'dentist'} type="radio" name="searchBy" id="searchByDentist" />
                      <label className="form-check-label" htmlFor="searchByDentist">
                        Dentist
                      </label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-check">
                      <input className="form-check-input" value="patient" onChange={handleSearchInputChange} checked={searchParams.searchBy === 'patient'} type="radio" name="searchBy" id="searchByPatient" />
                      <label className="form-check-label" htmlFor="searchByPatient">
                        Patient
                      </label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-group">
                      <input type='text' name="searchTerm" value={searchParams.searchTerm} onChange={handleSearchInputChange} className='form-control' placeholder='Search...' />
                      <button className="input-group-text" type='submit'>
                        <i className='bi bi-search'></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className='col-6 d-flex justify-content-end gap-3'>
                <div className="dropdown d-inline-block">
                  <button className="btn btn-light border dropdown-toggle hide-drop-icon"
                    data-bs-toggle="dropdown" aria-expanded="false">
                      <i className={`bi ${filterApplied ? 'text-primary bi-funnel-fill' : 'bi-funnel'}`}></i> Filters
                  </button>
                  <div className="dropdown-menu dropdown-menu-end" style={{zIndex: '1100'}}>
                    <div className='px-3 py-2'>
                      <div className="d-flex gap-3">
                        <div>
                          <label htmlFor='fromDate'>From:</label>
                          <input type="date" id="fromDate" name='fromDate' value={filters.fromDate} onChange={handleFilterInput} className="form-control" placeholder="From" />
                        </div>
                        <div>
                          <label htmlFor='toDate'>To:</label>
                          <input type="date" id="toDate" name='toDate' value={filters.toDate} onChange={handleFilterInput} className="form-control" placeholder="To" />
                        </div>
                      </div>
                      <div className="d-flex gap-3">
                        <button className="btn btn-primary mt-3" onClick={applyFilter}>Apply</button>
                        <button className="btn btn-light mt-3 border" onClick={clearFilter}>Clear</button>
                      </div>
                    </div>
                  </div>
                </div>
                <button className='btn btn-light border' onClick={() => toPDF()} title='Print'><i className='bi bi-printer'></i></button>
                <PlusIconButton className="fs-1" onClick={() => {navigate('/orders/new')}} />
              </div>
            </div>
          </div>
        </div>
        <div ref={targetRef}>
        {tableData}
        </div>
        {
          hasNextPage ?
            <div className='text-center'>
              <button className='btn btn-light border' onClick={fetchNextPage}>Load More</button>
            </div>
            : <></>
        }
        <div className='text-center'>{isFetching && !isFetchingNextPage ? 'Fetching...' : null}</div>
      </div>
    </Container>
    { showModal &&
      <Modal id='confirmDeleteModal' title='Delete Order' onClose={closeModal}>
        <ConfirmModal message={`Are you sure you want to delete order `} onConfirm={() => {deleteOrder(orderIdToDelete)}} onCancel={closeModal} />
      </Modal>
    }
    </>
  );
}
