import { useApi } from "./api.helper";

export function useOrderApi() {
  const api = useApi();

  return {
    search: (fetchFilters, nextPageToken) => {
      const queryParams = [];
      if (fetchFilters.searchTerm) {
        queryParams.push(`term=${fetchFilters.searchTerm}`)
        queryParams.push(`searchBy=${fetchFilters.searchBy}`);
      }
      fetchFilters.fromDate && queryParams.push(`from=${fetchFilters.fromDate}`);
      fetchFilters.toDate && queryParams.push(`to=${fetchFilters.toDate}`);
      nextPageToken && queryParams.push(`nextPageToken=${nextPageToken}`);
      let params = '';
      if (queryParams.length) {
        params = queryParams.join('&');
      }
      return api.get(`/v1/orders${params ? '?' + params : ''}`);
    },
    create: (data) => {
      return api.post('/v1/orders', {
        orderDate: data.orderDate,
        dentistId: data.dentist?.id,
        patientName: data.patientName,
        workInfo: data.workInfo,
        workType: data.workType,
        shade: data.shade,
        dispatchDate: data.dispatchDate,
        description: data.description,
      });
    },
    get: (recordId) => {
      return api.get(`/v1/orders/${recordId}`);
    },
    edit: (recordId, data) => {
      return api.put(`/v1/orders/${recordId}`, {
        dentistId: data.dentist?.id,
        patientName: data.patientName,
        workInfo: data.workInfo,
        workType: data.workType,
        shade: data.shade,
        dispatchDate: data.dispatchDate,
        description: data.description,
      });
    },
    delete: (recordId) => {
      return api.delete(`/v1/orders/${recordId}`);
    },
  }
}