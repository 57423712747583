import React from 'react';

function ConfirmModal({ message, onConfirm, onCancel }) {
  return (
    <div className="bg-body-tertiary">
      <div className='container-fluid'>
        {message}
      </div>
      <hr />
      <div className='container-fluid'>
        <div className='row my-2'>
          <div className='col gap-2 justify-content-between d-flex'>
            <button className='btn btn-outline-danger' type='button' onClick={() => {onCancel?.()}}>Cancel</button>
            <button className='btn btn-primary' type='button' onClick={() => {onConfirm?.()}}>Confirm</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
